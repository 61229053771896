import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"

const personalPage = () => (
  <Layout title='Personal'>
    <p>
      The Personal section of this blog is dedicated to insights I've drawn from experience. In sharing these reflections I hope others, like yourself, can avoid some of the mistakes I've made in stumbling through life. I'll often follow up an anecdote with a lesson I've learned, but the conclusions you draw are up to you.
      <br /><br />
      These writings are often intimate. Some are happier than others.
    </p>
    <br/><br/>
    <b><i>Published</i></b>
    <ul>
      <li><Link href="/personal/기억난다">기억난다</Link></li>
      <li><Link href="/personal/temet-nosce">Temet Nosce</Link></li>
      <li><Link href="/personal/personal-principles">Personal Principles (wip)</Link></li>
    </ul>
    <b><i>In the Works</i></b>
    <ul>
      <li><Link href="/personal/">What I Look for in People (drafting)</Link></li>
      <li><Link href="/personal/">Launching a Startup, the Wrong Way (outlining)</Link></li>
    </ul>
  </Layout>
)

export default personalPage

